import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Offer = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dn4qoce', 'template_1wbho6z', e.target, 'hv0kvtKmAkvCYQvRb')
      .then((result) => {
        setSuccessMessage('Email sent successfully!');
        setErrorMessage('');
        setEmail('');
      }, (error) => {
        setErrorMessage('An error occurred, please try again.');
        setSuccessMessage('');
      });
  };

  return (
    <div id='contact' className="bg-yellow p-6 md:p-8 flex flex-col items-center justify-start">
      <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl pt-8 pb-6 font-bold text-darkpink mb-4 text-center">
        JOIN OUR SOLEPLUS CLUB & GET 15% OFF
      </h2>
      <p className="text-base sm:text-lg md:text-xl text-darkpink mb-4 text-center">
        Sign up for free! Join the community.
      </p>
      <form onSubmit={handleSubmit} className="flex flex-col md:flex-row justify-center items-center w-full md:w-auto space-y-3 md:space-y-0 md:space-x-4">
        <input
          type="email"
          name="user_email"
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="px-4 py-2 w-64 md:w-80 border-2 border-black focus:outline-none rounded-md md:rounded-l-lg"
          required
        />
        <button type="submit" className="px-6 py-2 bg-black text-white font-bold rounded-md md:rounded-r-lg">
          SUBMIT
        </button>
      </form>
      {successMessage && <p className="text-green-600 mt-4">{successMessage}</p>}
      {errorMessage && <p className="text-red-600 mt-4">{errorMessage}</p>}
    </div>
  );
};

export default Offer;
