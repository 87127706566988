import React, { useState } from 'react';
import beadwork from '../assets/svg/Frame 55128 (1).svg';
import theradwork from '../assets/svg/Frame 40071.svg';
import grandbridalwork from '../assets/svg/Frame 40072 (1).svg';
import liningwork from '../assets/svg/Group 55121.svg';
import Carousel from '../components/Carousel';
import AboutUs from './About-us/AboutUs';
import Product from './Product/Product';
import Class from './Classes/Class';
import Offer from '../components/Offer';

const data = [
  {
    svg: beadwork,
    heading: 'Bead Work',
    text: 'Beads work is the art of stitching beads onto fabric for decorative patterns and embellishments.',
    extraText: 'Additional details about bead work can be included here. This may contain more examples, techniques, or historical context.',
  },
  {
    svg: theradwork,
    heading: 'Thread Work',
    text: 'Thread work is the art of stitching intricate designs on fabric using various threads.',
    extraText: 'Additional details about thread work can be included here, such as techniques and applications.',
  },
  {
    svg: grandbridalwork,
    heading: 'Grand Bridal Work',
    text: 'Grand bridal aari work is intricate embroidery with threads, beads, and stones, creating luxurious, ornate designs for bridal wear.',
    extraText: 'Additional insights about grand bridal work can include historical significance and style variations.',
  },
  {
    svg: liningwork,
    heading: 'Lining Aari Work',
    text: 'Lining aari work involves adding intricate embroidery along the edges or seams of fabric, enhancing the design with detailed, elegant patterns.',
    extraText: 'Further information on lining aari work can cover its techniques and typical uses in fashion.',
  },
];

function Home() {
  const [showMore, setShowMore] = useState(Array(data.length).fill(false));

  const toggleShowMore = (index) => {
    const newShowMore = [...showMore];
    newShowMore[index] = !newShowMore[index];
    setShowMore(newShowMore);
  };

  return (
    <div>
      <Carousel />
      <div id="home" className="bg-darkpink py-10">
        <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-8">
          {data.map((item, index) => (
            <div key={index} className="text-center text-yellow-300">
              <div className="flex justify-center mb-4">
                <img src={item.svg} alt={item.heading} className="h-16 w-16" />
              </div>
              <h3 className="text-lg text-yellow font-bold">{item.heading}</h3>
              <p className="mt-2 text-yellow text-pretty">{item.text}</p>
              <button onClick={() => toggleShowMore(index)} className="text-darkpink px-1 rounded-sm bg-yellow  mt-2">
  {showMore[index] ? '' : 'Learn More'}
</button>

              {showMore[index] && (
                <p className="mt-2 text-yellow text-pretty">{item.extraText}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <AboutUs />
      <Product />
      <Class />
      <Offer />
    </div>
  );
}

export default Home;
