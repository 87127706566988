import React from 'react';
import { useParams } from 'react-router-dom';
import flower from '../../assets/svg/Group.svg';
import bead1 from '../../assets/image/81ZWUy0X9QL._AC_UF894,1000_QL80_.jpg';
import bead2 from '../../assets/image/91vfo-advWL._AC_UY1000_.jpg';
import aari1 from '../../assets/image/6_1200x1200.webp';
import aari2 from '../../assets/image/maxresdefault.jpg';
import fab1 from '../../assets/image/navy-blue-dyed-pure-cotton-fabric-848983.jpg';
import fab2 from '../../assets/image/fabric-scaled-1.webp';
import needle1 from '../../assets/image/needle121.jpg';
import needle2 from '../../assets/image/product-jpeg.jpg';
import gold1 from '../../assets/image/gold1.jpg';
import gold3 from '../../assets/image/gold3.jpeg';
import thread1 from '../../assets/image/81ZWUy0X9QL._AC_UF894,1000_QL80_.jpg';
import thread2 from '../../assets/image/1final.jpg';
import image1 from '../../assets/image/images1.jpeg';
import image2 from '../../assets/image/a0nwq_512.jpg';
import stone1 from '../../assets/image/stone1.jpg';
import stone2 from '../../assets/image/stone2.jpg';

const ProductData = {
  1: {
    title: 'Beads',
    description: 'Bead, small usually round object made of glass, wood, metal, nut shell, bone, seed, or the like pierced for stringing.',
    images: [bead1, bead2],
  },
  2: {
    title: 'Aari Work Stand',
    description: 'Detailed description about the Aari Work Stand.',
    images: [aari1, aari2],
  },
  3: {
    title: 'Fabric',
    description: 'Detailed description about Fabric.',
    images: [fab1, fab2],
  },
  4: {
    title: 'Needles',
    description: 'Detailed description about Needles.',
    images: [needle1, needle2],
  },
  5: {
    title: 'Thread Gold',
    description: 'Detailed description about Thread Gold.',
    images: [gold1, gold3],
  },
  6: {
    title: 'Threads',
    description: 'Detailed description about Threads.',
    images: [thread1, thread2],
  },
  7: {
    title: 'Bunch Beads',
    description: 'Detailed description about Bunch Beads.',
    images: [image1, image2],
  },
  8: {
    title: 'Stones',
    description: 'Detailed description about Stones.',
    images: [stone1, stone2],
  },
};

function ProductPage() {
  const { id } = useParams();
  const product = ProductData[id] || {};

  if (!product.title) {
    return (
      <div className="flex justify-center items-center h-screen w-full bg-white">
        <p className="text-lg text-red-500">Product not found.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row justify-between items-center h-auto w-full overflow-hidden bg-orange-50 p-6 relative">
      <div className="absolute -left-32 lg:-left-72 -top-28 lg:top-0">
        <img className="w-64 lg:w-[700px]" src={flower} alt="Decorative Flower" />
      </div>
      <div className="z-10 pl-6 lg:pl-20 max-w-xl text-center lg:text-left pt-20 pb-10 lg:w-1/2">
        <p className="font-poppins text-3xl lg:text-4xl font-bold text-darkpink">{product.title}</p>
        <p className="mt-4 lg:mt-6 font-poppins text-base lg:text-lg text-darkpink leading-relaxed">{product.description}</p>
      </div>
      {product.images && (
        <div className="z-10 flex flex-col items-center pr-6 lg:pr-40 lg:items-end lg:w-1/2 gap-6 mt-10 lg:mt-0">
          {product.images.map((image, index) => (
            <div key={index} className="relative group">
              <img
                src={image}
                alt={`${product.title} image ${index + 1}`}
                className={`w-64 h-64 lg:w-72 lg:h-72 object-cover shadow-lg transition-transform duration-300 transform group-hover:scale-105 ${index === 0 ? 'rounded-full' : 'rounded-md'}`}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ProductPage;
