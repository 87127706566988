import React from "react";
import folwer2 from '../../assets/svg/flower 2.svg';
import folwer1 from '../../assets/svg/Group (1).svg';

function Pricing() {
  return (
    <div id="pricing" className="pt-10">
      <div className="bg-orange-50 p-4 sm:p-6 md:p-10 relative">
        <img 
          src={folwer2} 
          alt="background" 
          className="absolute top-[55%] lg:top-[33%] xl:top-[12%] md:top-[48%] left-5 md:left-10 w-[70%] sm:w-[70%] md:w-[50%] opacity-20 object-cover z-0" 
        />
        
        <img 
          src={folwer1} 
          alt="background" 
          className="absolute top-[4%] md:top-[6%] md:right-10 right-4 w-[70%] md:w-[50%] opacity-20 object-cover z-0" 
        />
        
        <div className="border-dashed border-2 h-[400px] sm:h-[500px] md:h-[600px] border-darkpink p-4 sm:p-6 relative">
          <h1 className="text-center text-darkpink text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 md:mb-10">
            Pricing
          </h1>

          <div className="h-[320px] sm:h-[350px] md:h-[490px] overflow-auto no-scrollbar absolute">
            <table className="w-full table-fixed border-separate border-spacing-y-2 sm:border-spacing-y-4">
              <thead className="sticky top-0 z-10">
                <tr>
                  <th className="px-4 sm:px-6 md:px-20 py-2 bg-yellow text-darkpink text-center">ITEMS</th>
                  <th className="px-4 sm:px-6 md:px-20 py-2 bg-yellow text-darkpink text-center">₹ PRICE</th>
                </tr>
              </thead>
              <tbody className="text-yellow">
                {[
                  { item: "SAREE FALLS", price: "₹ 15.00" },
                  { item: "LYNING CLOTH", price: "₹ 30.00" },
                  { item: "SUGAR BEEDS (A STAR)", price: "₹ 180.00" },
                  { item: "SUGAR BEEDS (PRISO)", price: "₹ 350.00" },
                  { item: "2 mm Beeds", price: "₹ 20.00" },
                  { item: "4mm Beeds", price: "₹ 40.00" },
                  { item: "3mm Beeds", price: "₹ 30.00" },
                  { item: "5mm Beeds", price: "₹ 55.00" },
                  { item: "Stone Chain Small", price: "₹ 25.00" },
                  { item: "Stone Chain Big", price: "₹ 30.00" },
                  { item: "Lace Starting Rate", price: "₹ 10.00" },
                  { item: "Saree kunjan", price: "₹ 50.00" },
                  { item: "Silk thread", price: "₹ 17.00" },
                  { item: "Normal Cotton thread", price: "₹ 5.00" },
                  { item: "ALL Fancy Items Starting", price: "₹ 5.00" },
                  { item: "Aari Work Material Starting Price", price: "₹ 5.00" },
                  { item: "Tailoring Material Starting Price", price: "₹ 01.00" },
                  { item: "Stationary Items", price: "₹ 700.00 to ₹ 1500.00" },
                  { item: "Normal Blouse", price: "₹ 5000.00 to ₹ 20000.00" },
                  { item: "Embroidery Aari", price: "₹ 10.00 to 100.00" },
                  { item: "Stone/lace, Hangings and etc", price: "₹ 30.00" },
                  { item: "Lining 1 Meter", price: "₹ 30.00" }
                ].map((row, index) => (
                  <tr key={index} className="border-b border-dotted border-yellow-400 text-center">
                    <td className="px-2 sm:px-4 md:px-6 text-darkpink font-medium py-2">{row.item}</td>
                    <td className="px-2 sm:px-4 md:px-6 text-darkpink font-medium py-2">{row.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
